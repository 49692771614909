<template>
    <div class="d-flex justify-content-center align-items-center vh-100">
      <div class="card text-center">
        <div class="card-header">
          It seems you are lost!
        </div>
        <div class="card-body">
        <div class="image-container">
            <img :src="getSuccessGifPath()" class="circle-image" alt="Happy Gif">
        </div>
          <h5 class="card-title">Oops!</h5>
          <p class="card-text">
            Well, this is awkward. We can't find the page you're looking for.
          </p>
          <a href="#" class="btn btn-primary">Download Tappie</a>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'NotFound',
    methods: {
        getSuccessGifPath(){
            const randomNumber = Math.floor(Math.random() * 3) + 1;
            return require(`@/assets/gifs/lost${randomNumber}.webp`);
        }
    }
  }
  </script>
  
  <style scoped>
  .vh-100 {
    height: 100vh;
  }
  .circle-image {
    width: 170px;
    height: 170px;
    border-radius: 50%;
    object-fit: cover;
    margin: 10px;
  }
  .image-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  .card{
    background-color: #202859;
    color:#E6E6FA;
    text-align: left;
    box-shadow: rgba(0, 0, 0, 0.5) 0px 5px 38px -5px;
    margin-left: 15px !important;
    margin-right: 15px !important;
  }
  </style>
  