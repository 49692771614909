<template>
    <PaymentForm />
</template>
  
<script>
  import PaymentForm from '@/components/PaymentForm.vue'
  
  export default {
    name: 'PaymentFormView',
    components: {
        PaymentForm
    }
  }
</script>
  