<template>
    <div class="payment-container">
      <p>
        Pay with  
        <img :src="require('@/assets/logo-wide.png')"  alt="Tappie Logo" class="pay-with-logo">
      </p>
      <div class="card">
       
        <div class="card-body">
          <div v-if="state === 'LOADING'" class="loading">
            Loading...
          </div>

          <div v-if="state === 'COLLECTING_DATA'" class="payment-data">
            
            <p class="purpose">{{ paymentData.purpose }}</p>
            <p class="recepient">To {{ paymentData.initiator_name }}</p>
            <p class="amount">{{ paymentData.amount }}/=</p>
            <button class="add-message" @click="addMessage">
              <i class="bi bi-plus"></i> Add message?
            </button>
            
            <div class="form-group d-none" id="message-box">
              <label for="messageField" class="lbl">Message:</label>
              <input type="text" class="form-control" id="messageField" />
              <br>
            </div>

            <div class="form-group">
              <label for="phoneNumberField" class="lbl">My Phone Number:</label>
              <input type="number"
                class="form-control"
                id="phoneNumberField"
                v-model="phoneNumber"
                placeholder="07xxxxxxx"/>
            </div>

            <button class="btn btn-pay-now" @click="initiatePayment">Pay now</button>
          </div>

          <div v-if="state === 'PAYING'" class="paying">
            <p>
              A prompt has been sent to your phone.
              Please check your phone to complete the transaction.
            </p>
            
            <div class="spinner-border text-primary" role="status">
            </div>
            <div class="spinner-border text-secondary" role="status">
            </div>
            <div class="spinner-border text-info" role="status">
            </div>

          </div>

          <div v-if="state === 'PAID'" class="paying">

            <div class="image-container">
              <img :src="getSuccessGifPath()" class="circle-image" alt="Happy Gif">
            </div>

            <p>
              <strong>Done, thx</strong>
            </p>
            <p>
              The receipient is really happy that you have paid
            </p>
          </div>

          <div v-if="state === 'CANCELED'" class="paying">

            <div class="image-container">
              <img :src="getSadGifPath()" class="circle-image" alt="Happy Gif">
            </div>
            <p>
              Hmmmm, it looks like you did not complete the payment.
            </p>

            <p>
              Do you want to <a href="#">try again</a>
            </p>
          </div>

          <div v-if="state === 'KNOWN_ERROR'" class="error">
            Known error has happened
          </div>
          
          <div v-if="state === 'GENERIC_ERROR'" class="error">
            <div class="image-container">
              <img :src="getConfusedGifPath()" class="circle-image" alt="Happy Gif">
            </div>
            <p>
              Something went wrong. Please check your internet and try again!
            </p>
          </div>

        </div>

        <div class="card-footer">
          By using Tappie, you accept the <br /><a href="#">Terms of use</a>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';

  export default {
    name: 'PaymentForm',
    props: ['id'],
    data() {
      return{
        state: 'LOADING',
        phoneNumber: '',
        paymentData: {
          initiator_name: '',
          purpose: '',
          amount: 0
        },
      }
    },
    created() {
      this.fetchPaymentData();
      const storedPhoneNumber = this.getPhoneNumber();
      if (storedPhoneNumber) {
        this.phoneNumber = storedPhoneNumber;
      }
    },
    methods: {
      async fetchPaymentData() {
        try {
          const tappieDetailsAPI = `https://tappie.me/api/v1/tappie/${this.id}`
          const response = await axios.get( tappieDetailsAPI );

          if(response.status !== 200){
            throw new Error(`Received non success status code ${response.status}`);
          }

          const responseData = response.data.data;
          if (!responseData || responseData.length === 0) {
            console.log("Invalid response: Maybe id does not exist");
            throw new Error("Received No data from the server");
          }

          const tappie_identifier = responseData.tappie_identifier;
          if(tappie_identifier !== this.id){
            console.log("Payment identfier does not match the id");
          }

          const amount = responseData.amount;
          const purpose = responseData.purpose;

          this.paymentData.initiator_name = 'Mr. Dave';
          this.paymentData.amount = amount;
          this.paymentData.purpose = purpose;
          this.state = 'COLLECTING_DATA'

        } catch (error) {
          this.state = 'GENERIC_ERROR'
        }
      },
      async initiatePayment() {
        const message = document.getElementById('messageField').value;
        const phoneNumber = document.getElementById('phoneNumberField').value;

        if (!/^\d{10}$/.test(phoneNumber)) {
          alert("Please check that you have entered a correct phone number")
          return;
        }

        this.storePhoneNumber(phoneNumber);

        const paymentData = {
          data: {
            tappie_identifier: this.id,
            payer_account: phoneNumber,
            message: message,
            amount: this.paymentData.amount
          }
        };

        try {
          const payment_url = 'https://tappie.me/api/v1/payment';
          const response = await axios.post(payment_url, paymentData);

          if (response.status === 200) {
            const responseData = response.data;
            const trackingId = responseData.data.payment_identifier
            this.state = 'PAYING'
            this.trackTransaction(trackingId);
          } else {
            throw new Error(`Error: Received status code ${response.status}`);
          }
        } catch (error) {
          this.state = 'GENERIC_ERROR'
        }
      },
      trackTransaction(trackingId) {
        const checkStatus = async () => {

          try {
          const trackingUrl = `https://tappie.me/api/v1/payment/${trackingId}`;
          const response = await axios.get(trackingUrl);
          if (response.status === 200) {
                const responseData = response.data;
                const status = responseData.data.status;

                /**
                 * Confirm with the server API about the different statuses and how we can handle them
                 */
                if (status === 'success') {
                  clearInterval(intervalId);
                  this.state = 'PAID'
                }
                else if(status === 'cancelled'){
                  clearInterval(intervalId);
                  this.state = 'CANCELED'
                }
              } 
            } catch (error) {
              console.error('Error checking transaction status:', error);
            }
        };

        const intervalId = setInterval(checkStatus, 5000);
        checkStatus();
      },
      addMessage() {
        const messageBox = document.getElementById('message-box');
        if (messageBox.classList.contains('d-none')) {
          messageBox.classList.remove('d-none');
          messageBox.classList.add('d-block');
        } else {
          messageBox.classList.remove('d-block');
          messageBox.classList.add('d-none');
        }
      },
      getSuccessGifPath(){
        const randomNumber = Math.floor(Math.random() * 6) + 1;
        return require(`@/assets/gifs/money${randomNumber}.webp`);
      },
      getSadGifPath(){
        const randomNumber = Math.floor(Math.random() * 4) + 1;
        return require(`@/assets/gifs/annoyed${randomNumber}.webp`);
      },
      getConfusedGifPath(){
        const randomNumber = Math.floor(Math.random() * 5) + 1;
        return require(`@/assets/gifs/confused${randomNumber}.webp`);
      },
      storePhoneNumber(phoneNumber) {
        localStorage.setItem('phoneNumber', phoneNumber);
      },
      getPhoneNumber() {
        return localStorage.getItem('phoneNumber');
      }
    }
  }

  </script>
  
  <style scoped>
  .payment-container {
      max-width: 400px;
      margin: auto;
      padding-top: 50px;
    }

    .pay-with-logo{
      height: 75px;
      position: relative;
      left: -15px;
    }

    .btn-primary, .btn-success {
      width: 100%;
    }
    .payment-container {
    max-width: 400px;
    margin: auto;
    padding-top: 50px;
    font-family: 'Roboto', sans-serif;
  }
  .circle-image {
    width: 170px;
    height: 170px;
    border-radius: 50%;
    object-fit: cover;
    margin: 10px;
  }
  .image-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }

  .card{
    background-color: #202859;
    color:#E6E6FA;
    text-align: left;
    box-shadow: rgba(0, 0, 0, 0.5) 0px 5px 38px -5px;
    margin-left: 15px !important;
    margin-right: 15px !important;
  }

  .card .purpose{
    font-size: 2em;
    font-weight: bold;
  }

  .card .recepient{
    font-weight: bold;
  }

  .card .amount{
    font-weight: bold;
    font-size: 3em;
  }

  .card .lbl{
    font-weight: bold;
    margin-bottom: 5px;
  }

  .card .add-message{
    border: none;
    border-radius: 10px;
    background-color: rgba(0,0,0,0.35);
    color:#E6E6FA;
    padding: 8px 12px;
    border-top-left-radius: 0;
    font-weight: bold;
    font-size: 0.8em;
  }

  .card .btn-pay-now{
    margin: 27px 0;
    display: block;
    width: 100%;
    background: teal;
    color: #E6E6FA;
    padding: 10px 0;
    font-weight: bold;
  }

  .form-group{
    margin-top: 15px;
  }

  .card-footer{
    background-color: #2c3679;
    color: #aaa;
    text-align: center;
    padding: 20px;
  }

  .card-footer a{
    color: #E6E6FA;
    text-decoration: none;
    font-size: 0.75em;
  } 
  </style>
  